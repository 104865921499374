@use '@/ui/style/sass';

.terms_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: sass.ptr(32);
  color: sass.$gray_80;

  @include sass.base(sass.ptr(14), 140%, sass.$font-weight-normal);

  .toc {
    display: flex;
    align-items: flex-start;
    padding: sass.ptr(16);
    gap: sass.ptr(16);
    border-radius: sass.ptr(8);
    background: sass.$gray_10;
    color: sass.$black;

    > ul {
      display: flex;
      flex: 1 1 0;
      flex-direction: column;
      gap: sass.ptr(8);
      list-style: none;

      > li {
        padding-left: 1em;
        text-indent: -1em;

        &::before {
          content: '•';
          margin-right: sass.ptr(4);
          font-size: sass.ptr(14);
        }
      }
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: sass.ptr(8);

    @include sass.base(sass.ptr(12), 140%, sass.$font-weight-normal);

    &.no_list_style {
      ol,
      ul {
        list-style: none;
      }

      ol ol,
      ol ul,
      ul ol,
      ul ul {
        padding-inline-start: sass.ptr(12);
      }
    }

    ol {
      list-style-type: none;
      counter-reset: main-counter;
    }

    ol > li {
      padding-left: 1em;
      text-indent: -1em;
      counter-increment: main-counter;
    }

    ol > li::before {
      content: counter(main-counter) '. ';
      font-weight: bold;
    }

    ol ol {
      counter-reset: sub-counter;
    }

    ol ol > li {
      counter-increment: sub-counter;
    }

    ol ol > li::before {
      content: counter(main-counter) '.' counter(sub-counter) ' ';
      font-weight: bold;
    }

    ol ol ol {
      counter-reset: third-counter;
    }

    ol ol ol > li {
      counter-increment: third-counter;
    }

    ol ol ol > li::before {
      content: counter(main-counter) '.' counter(sub-counter) '.' counter(third-counter) ' ';
      font-weight: bold;
    }

    ol ol ol ol {
      counter-reset: fourth-counter;
    }

    ol ol ol ol > li {
      counter-increment: fourth-counter;
    }

    ol ol ol ol > li::before {
      content: counter(main-counter) '.' counter(sub-counter) '.' counter(third-counter) '.' counter(fourth-counter) ' ';
      font-weight: bold;
    }

    > div {
      display: flex;
      flex-direction: column;
      gap: sass.ptr(8);
    }

    > :first-child,
    > div:first-child > h3,
    > div:first-child > h2 {
      margin-top: 0;
    }

    h2 {
      margin-top: sass.ptr(16);
      @include sass.base(sass.ptr(16), 140%, sass.$font-weight-bold);
    }

    h3 {
      margin-top: sass.ptr(16);
      @include sass.base(sass.ptr(14), 140%, sass.$font-weight-bold);
    }

    a {
      color: sass.$black;
      text-decoration: underline;
    }

    table {
      border-collapse: collapse;
      border: 1px solid sass.$gray_80;

      th {
        background: sass.$gray_10;
      }

      th,
      td {
        padding: sass.ptr(4) sass.ptr(8);
        border: 1px solid sass.$gray_80;
      }
    }
  }
}

@include sass.htmlConditioner(false, 'mo') {
  .terms_container {
    gap: sass.ptr(24);

    .toc {
      flex-direction: column;
      gap: sass.ptr(4);
      margin: 0 sass.ptr(16);

      > ul {
        gap: sass.ptr(4);
      }
    }

    .body {
      padding: 0 sass.ptr(16);
    }
  }
}
